import React from "react";

import Layout from "../components/layout";
import PurpleRow from "../components/purpleRow";
import SinglePrice from "../components/singlePrice";
import OKSign from "../assets/images/feather.png";

const PricesPage = () => (
  <Layout title="Cennik">
    <div className="wrapper">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="description main-desc">
                <h2>Rozwój i wygoda w dobrej cenie</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className=" main-desc">
              <div className="row">
                <SinglePrice
                  title="Mała placówka"
                  subtitle="do 25 osób"
                  teaser="Dla małych przedszkoli, do których uczęszcza do 25 dzieci"
                  price="62 PLN"
                  type="small"
                />
                <SinglePrice
                  title="Średnia placówka"
                  subtitle="do 50 osób"
                  teaser="Dla średnich przedszkoli, do których uczęszcza do 50 dzieci"
                  price="123 PLN"
                  type="middle"
                />
                <SinglePrice
                  title="Duża placówka"
                  subtitle="do 100 osób"
                  teaser="Dla dużych przedszkoli, do których uczęszcza do 100 dzieci"
                  price="246 PLN"
                  type="large"
                />
                <SinglePrice
                  title="Indywidualny"
                  subtitle="od 100 osób"
                  teaser="Dla tych, którzy potrzebują więcej."
                  price="od 50 PLN"
                  type="other"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section grey-background">
      <div className="container">
        <div className="row wyg">
          <div>
            <div className="main-desc">
              <h2>Co zyskujesz?</h2>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-xs-12">
            <p className="wyg-faq-part-title">Płatności</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              automatyczne naliczanie opłat
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              rozliczanie wpłat od rodziców
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              możliwość tworzenia rachunków grupowych
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              wysyłka grupowa lub pojedyncza na (telefon/komputer/e-mail)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              filtrowanie i wysyłanie informacji o zaległościach
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              podgląd kto odczytał informację
            </p>
          </div>

          <div className="col-sm-6 col-md-4  col-xs-12">
            <p className="wyg-faq-part-title">Komunikacja</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              własna tablica
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              Posty zawierające informacje, zdjęcia oraz aktywne linki
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              e-nauczanie (zdalne)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              komunikaty do poszczególnych grup
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              komunikaty do wszystkich rodziców
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              komunikaty do wszystkich pracowników
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              pliki (Excel, World, PDF) do pobrania i wydruku
            </p>
          </div>
          <div className="col-sm-6 col-md-4  col-xs-12">
            <p className="wyg-faq-part-title">Personalizacja</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              własne logo
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              rejestr nieobecności (dzień, godzina)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              przyjmowanie nieobecności po terminie
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              ustawienie opłat (naliczanie stałe, dzienne, własne)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part" >
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              indywidualne opłaty (per dziecko)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              numer rachunku bankowego
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              termin płatności
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-xs-12">
            <p className="wyg-faq-part-title">Nieobecności</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              system zgłaszania nieobecności
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              zgłaszanie nieobecności przez rodziców
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              dodanie nieobecności przez placówkę
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              potwierdzanie i anulowanie zgłoszenia
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              raport o nieobecności (ile zgłoszeń i ile jest aktualnie dzieci)
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-xs-12">
            <p className="wyg-faq-part-title">Wsparcie</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              pełne wsparcie z naszej strony
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              szkolenie online
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              zdalne rozwiązywanie problemów rodziców
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-xs-12">
            <p className="wyg-faq-part-title">Platformy</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              Apple iOS (telefon)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              Android (telefon)
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              Aplikacja Webowa (komputer)
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-xs-12">
            <p className="wyg-faq-part-title">Raporty</p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              generowanie raportów płatniczych
            </p>
            <p className="wyg-faq-part-text hiw-faq-part">
              <img className="hiw-faq-part-icon" src={OKSign} alt="" />
              raporty miesięczne/ kwartalne / roczne
            </p>
          </div>
        </div>
      </div>
    </div>
    <PurpleRow>
      Dla tych, <br /> którzy potrzebują więcej.
    </PurpleRow>
  </Layout>
);

export default PricesPage;
