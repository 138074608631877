import React from "react";
import { Link } from "gatsby";

const SinglePrice = ({ title, subtitle, teaser, price, type }) => (
  <div className="col-md-3 col-xs-12 col-sm-6 no-padding-card">
    <div className="card card-small-margin price-wrapper">
      <p className="price-title">{title}</p>
      <p className="price-subtitle">{subtitle}</p>
      <p className="price-teaser">{teaser}</p>
      <p className="price-price">{price}</p>
      <p className="price-month">/ miesiąc</p>
      <Link to={"/zamow?type=" + type}>
        <button className="violet-btn btn desc-btn">Wybierz</button>
      </Link>
    </div>
  </div>
);

export default SinglePrice;
